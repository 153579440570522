import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import BoxItem from '../Components/BoxItem/BoxItem';
import DeleteModal from '../Components/DeleteModal';
import SearchInput from '../Components/Search';
import CustomSelect from '../Components/SelectOption/SelectOption';
import Spinner from '../Components/Spinner';
import StockModal from '../Components/StocksModal/StockModal';
import StockModalEdit from '../Components/StocksModal/StockModalEdit';
import { ReactComponent as AdditonIcon } from '../Icons/addition.svg';
import { ReactComponent as ColorIcon } from '../Icons/color.svg';
import '../Styles/StockReport/StockReport.css';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  IconButton,
} from '@mui/material';

function Accessories() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [itemStock, setItemStock] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [refresh, setRefresh] = useState(false);
  // const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchItems, setSearchItems] = useState({
    barCode: '',
    partName: '',
    model: '',
    color: '',
  });
  const [customSelectOpen, setCustomSelectOpen] = useState(false);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const [stockItems, setStockItems] = useState(null);

  const [colorListOpen, setColorListOpen] = useState(false);
  const [selectedStockId, setSelectedStockId] = useState();

  const [priceSelectOpen, setPriceSelectOpen] = useState(false);
  const [deleteModal, setModalDelete] = useState(false);
  const fileInputRef = useRef(null);

  const [filterItem, setFilterItem] = useState({
    minPrice: 0,
    maxPrice: 1000000,
    color: '',
    vendor: '',
  });

  const [colorOptions, setColorOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [shopListOpen, setShopListOpen] = useState(false);

  const DEBOUNCE_DELAY = 500; // Adjust the delay as needed

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchItems);
  const [selectedStock, setSelectedStock] = useState(null);

  const [clear, setClear] = useState(false);
  const [stockStats, setStockStats] = useState({
    totalInStock: 0,
    totalOutOfStock: 0,
  });

  const [selectedShop, setSelectedShop] = useState();
  const [shopsList, setShopsList] = useState([]);

  const handleSelectItem = (event, itemId) => {
    const selectedItem = selectedItems.find((id) => id == itemId);
    let newSelected = null

    if (!selectedItem) {
      setSelectedItems(st => [...st, itemId]);
    } else {
      setSelectedItems(st => st.filter((id) => id !== itemId));
    }

  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = itemStock.map((item) => item._id);
      setSelectedItems(newSelecteds);
    } else {
      setSelectedItems([]);
    }
  };

  const isItemSelected = (itemId) => selectedItems.findIndex((id) => id === itemId) !== -1;

  const deleteItems = async (itemIds) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/stock/bulk-delete`,
        { ids: itemIds }
      );

      return response.data;
    } catch (error) {
      console.error('Error while deleting items:', error);
    }
  };

  const handleBulkDelete = () => {
    // Implement the logic to delete selected items
    // For example, make an API call to delete items from the database
    // Then update the itemStock state

    // Example (assuming you have a deleteItems function that accepts an array of IDs):
    deleteItems(selectedItems).then(() => {
      const remainingItems = itemStock.filter((item) => !selectedItems.includes(item._id));
      setItemStock(remainingItems);
      setSelectedItems([]);
    });
  };


  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );

      // Create the shopOptions array from the response data
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error('Error while fetching shop options: ', error);
    }
  };

  useEffect(() => {
    fetchColorOptions();
    fetchShopsOptions();
    fetchVendorOptions();
    fetchStockStats();
  }, [selectedShop]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchItems);
    }, DEBOUNCE_DELAY);

    return () => {
      clearTimeout(timer);
    };
  }, [searchItems]);

  useEffect(() => {
    fetchItemStock();
  }, [refresh, debouncedSearchTerm, filterItem, selectedShop]);

  const fetchStockStats = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/stock/stat?isAccessory=true&shop=${selectedShop}`
      );
      let stats = response.data.data[0];
      if (!stats) {
        stats = {
          totalInStock: 0,
          totalOutOfStock: 0,
        }
      }
      setStockStats(stats);
    } catch (error) {
      console.error('Error while fetching stock stats: ', error);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming first sheet is needed

      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      setStockItems(sheetData);
    };

    reader.readAsBinaryString(file);
  };

  const fetchItemStock = async () => {
    try {
      const queryParams = [];

      // if (filterItem.maxPrice !== 1000000) {
      //   queryParams.push(
      //     `price=${filterItem.minPrice || 0}-${filterItem.maxPrice || 1000000}`
      //   );
      // }
      // if (filterItem.color) {
      //   queryParams.push(`color=${filterItem.color}`);
      // }
      // if (filterItem.vendor) {
      //   queryParams.push(`vender=${filterItem.vendor}`);
      // }
      // if (debouncedSearchTerm) {
      //   queryParams.push(`search=${debouncedSearchTerm}`);
      // }

      for (const key in searchItems) {
        if (searchItems[key]) {
          queryParams.push(`${key}=${searchItems[key]}`);
        }
      }

      // Join the query parameters with '&' to form the complete query string
      const queryString = queryParams.join('&');

      // Construct the API URL with query parameters
      const apiUrl = queryString
        ? `stock?isAccessory=${true}&${queryString}`
        : `stock?isAccessory=${true}`;

      const shopIdUrl = selectedShop
        ? `${apiUrl}&shop=${selectedShop}`
        : `${apiUrl}`;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${shopIdUrl}&pageNumber=1&recordsPerPage=20000`
      );
      setTotalItems(response.data.data.count);
      setItemStock(response.data.data.stockList.reverse());
      console.log('accessories,', response.data.data.stockList.reverse());
    } catch (error) {
      console.error('Error while fetching item stock: ', error);
    }
  };
  const resetItemStock = async () => {
    try {
      const queryParams = [];

      // queryParams.push(`price=${0}-${1000000}`);

      // if (filterItem.color) {
      //   queryParams.push(`color=${filterItem.color}`);
      // }
      // if (filterItem.vendor) {
      //   queryParams.push(`vender=${filterItem.vendor}`);
      // }
      // if (debouncedSearchTerm) {
      //   queryParams.push(`search=${debouncedSearchTerm}`);
      // }

      for (const key in searchItems) {
        if (searchItems[key]) {
          queryParams.push(`${key}=${searchItems[key]}`);
        }
      }

      // Join the query parameters with '&' to form the complete query string
      const queryString = queryParams.join('&');

      // Construct the API URL with query parameters
      const apiUrl = queryString
        ? `stock?isAccessory=${true}&${queryString}`
        : `stock?isAccessory=${true}`;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${apiUrl}`
      );
      setTotalItems(response.data.data.count);
      setItemStock(response.data.data.stockList.reverse());
      console.log('accessories,', response.data.data.stockList.reverse());
    } catch (error) {
      console.error('Error while fetching item stock: ', error);
    }
  };

  const addStockItem = async (data) => {
    setRefresh(true);

    try {
      // Fetch all items from the stock list
      const allItems = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/stock`
      );

      // Check if the product with the given barcode already exists in the stock list
      const existingProduct = allItems.data.data.stockList.find(
        (item) => item.barCode == data.barCode
      );

      if (existingProduct) {
        // Product with the same barcode already exists, show a message to the user
        toast.info('Product with the same barcode already exists in stock!');
        setIsModalOpen(true);
      } else {
        // Product doesn't exist, proceed with adding it to stock

        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/stock`, {
          ...data,
        });
        setRefresh(false); // This can be used to refresh the stock list.
        closeModal();
        toast.success('Product has been added in stock successfully!');
      }
    } catch (error) {
      console.log('error', error);
      console.error(
        error?.message || 'Error while adding item to stock, try again'
      );
      toast.error(`Error while adding stock item. Please try again later!`);
    }
  };

  const fetchColorOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/stock/colors?shop=${selectedShop}`
      );
      // Create the colorOptions array from the response data
      const colors = response.data.data.map((color) => ({
        label: color,
        value: color,
      }));
      setColorOptions(colors);
    } catch (error) {
      console.error('Error while fetching color options: ', error);
    }
  };

  const fetchVendorOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/stock/venders?shop=${selectedShop}`
      );
      // Create the colorOptions array from the response data
      const vendors = response.data.data.map((vendor) => ({
        label: vendor,
        value: vendor,
      }));
      setVendorOptions(vendors);
    } catch (error) {
      console.error('Error while fetching color options: ', error);
    }
  };

  const priceOptions = [
    { label: '100', value: 100 },
    { label: '200', value: 200 },
    { label: '300', value: 300 },
    { label: '400', value: 400 },
    { label: '599.99', value: 599.99 },
    { label: '600', value: 600 },
    { label: '10500', value: 10500 },
  ];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModals = (stock) => {
    setSelectedStock(stock);
    setIsModalOpens(true);
  };

  const closeModals = () => {
    setIsModalOpens(false);
    setSelectedStock(null);
  };
  const closeSelect = () => {
    setCustomSelectOpen(false);
    setCustomSelectOpen2(false);
  };
  const closeSelect2 = () => {
    setCustomSelectOpen2(false);
    setPriceSelectOpen(false);
  };
  const closeSelect3 = () => {
    setCustomSelectOpen(false);
    setPriceSelectOpen(false);
  };
  const openModalDelete = (stockId) => {
    setModalDelete(true);
    setSelectedStockId(stockId);
  };

  const closeModalDelete = () => {
    setModalDelete(false);
  };

  const updateStock = async (updatedData) => {
    setRefresh(true);
    console.log(updatedData);
    try {
      // Include the stockId in the request body
      updatedData.stockId = selectedStock._id;

      // Send a PUT request to update the stock
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/stock`,
        updatedData
      );

      // Handle the response, e.g., show a success message
      console.log('Stock updated successfully:', response.data);
      setRefresh(false); // This can be used to refresh the staff member list.

      toast.success('Stock updated successfully');
      // Close the modal after updating
      closeModals();
    } catch (error) {
      console.error('Error updating stock:', error);
      toast.error('Error while updating stock');

      // Handle the error, e.g., show an error message
    }
  };

  const bulkUpload = async (data) => {
    setRefresh(true);
    data = data.map((item) => {
      return {
        barCode: item.barCode.toString(),
        vender: item.vender,
        model: '' + item.model,
        partName: item.partName,
        price: Number(item.price),
        color: item.color,
        itemsInStock: Number(item.itemsInStock),
        isAccessory: Boolean(item.isAccessory),
        shopNo: item.shopNo
      };
    });
    // return;
    try {
      // Product doesn't exist, proceed with adding it to stock
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/stock/bulk?isAccessory=true`,
        data
      );
      setRefresh(false); // This can be used to refresh the stock list.
      closeModal();
      setStockItems(null);
      toast.success('Product has been added in zccessories successfully!');
    } catch (error) {
      const errorMessage = error.response.data.message;
      console.log(errorMessage);

      const extractDuplicateInfo = (errorMessage) => {
        const regex = /{([^}]+)}/; // Regular expression to match text inside curly braces
        const match = errorMessage.match(regex); // Extracts the text inside curly braces

        if (match && match[1]) {
          const keyValuePairs = match[1].split(', '); // Splits key-value pairs by comma and space
          const keyValueArray = keyValuePairs.map((pair) => {
            const [key, value] = pair.split(': '); // Splits key-value pairs by colon
            return { key: key.trim(), value: value.replace(/"/g, '').trim() };
          });

          return keyValueArray;
        }

        return null;
      };

      const extractedInfo = extractDuplicateInfo(errorMessage);
      console.log(extractedInfo);

      console.error(`Error while adding stock item:`, error);
      if (
        errorMessage.startsWith(
          'E11000 duplicate key error collection: POS.stocks index: barCode_1 dup key'
        )
      ) {
        toast.error(`Barcode ${extractedInfo[0].value} already exist`);
        return;
      }
      toast.error(`Error while adding stock item. Please try again later!`);
    }
  };

  const deleteStock = async (selectedStockId) => {
    setRefresh(true);

    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/stock/${selectedStockId}`
      );
      setRefresh(false);
      setItemStock((prevStock) =>
        prevStock.filter((stock) => stock._id !== selectedStockId).reverse()
      );
      setModalDelete(false);
      toast.success('Stock Item is deleted successfully');
    } catch (error) {
      console.error('Error while deleting Stock Item: ', error);
      toast.error('Error while deleting Stock item: ', error);
    }
  };
  const handleShopSelect = (selectedOption) => {
    setSelectedShop(selectedOption);
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const [tableSpinner, setTableSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setTableSpinner(false);
    }, 4000);
  }, []);
  const clearValues = () => {
    setFilterItem({ minPrice: 0, maxPrice: 1000000, color: '', vendor: '' });
    setClear(true);
    setTimeout(() => {
      setClear(false);
    }, 100);
  };

  useEffect(() => {
    if (stockItems) {
      bulkUpload(stockItems);
    }
  }, stockItems);

  const handletxtChange = (e) => {
    setSearchItems((st) => ({ ...st, [e.target.name]: e.target.value }));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='stock-container'>
          <div className='row-container'>
            <BoxItem
              text='Items In Stock'
              number={stockStats.totalInStock}
              icon={false}
              background=' linear-gradient(180deg, #0484AC 0%, #00AADF 100%)'
            />
            <BoxItem
              text='Items Out Stock'
              number={stockStats.totalOutOfStock}
              icon={false}
              background=' linear-gradient(180deg, #AD2626 0%, #EE5353 100%)'
            />
            <div
              className='button-box'
              onClick={() => {
                openModal();
              }}
            >
              <div className='icon'>
                <AdditonIcon />
              </div>
              <h3>Add New Item</h3>
            </div>
            <div
              className='button-box'
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
            >
              <div className='icon'>
                <AdditonIcon />
              </div>
              <input
                ref={fileInputRef}
                type='file'
                accept='.xlsx, .xls'
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                id='file-upload'
              />
              <h3 htmlFor='file-upload'>Bulk Add</h3>
            </div>
          </div>
          <div className='hsl'>
            <div className='search-row'>
              <SearchInput
                placeholder='Model'
                searchTerm={searchItems.model}
                onChange={handletxtChange}
                name='model'
              />
              <SearchInput
                placeholder='Part'
                searchTerm={searchItems.partName}
                onChange={handletxtChange}
                name='partName'
              />
              <SearchInput
                placeholder='Color'
                searchTerm={searchItems.color}
                onChange={handletxtChange}
                name='color'
              />
              <SearchInput
                placeholder='BarCode'
                searchTerm={searchItems.barCode}
                onChange={handletxtChange}
                name='barCode'
              />
              {/* <button className='search-button' onClick={fetchItemStock}>
                Search
              </button> */}
            </div>
            <div
              className='selectoptionscontainer'
              style={{ marginLeft: '10px' }}
            >
              {/* <CustomSelect
                options={[{ label: 'None', value: undefined }, ...colorOptions]}
                isOpen={colorListOpen}
                setIsOpen={setColorListOpen}
                onClick={closeSelect3}
                clear={clear}
                name='Color Options'
                icon={<ColorIcon />}
                onSelect={(c) => setFilterItem({ ...filterItem, color: c })}
                placeholder='Select Shop'
              />{' '} */}
              <CustomSelect
                options={[{ label: 'None', value: undefined }, ...shopsList]}
                isOpen={shopListOpen}
                setIsOpen={setShopListOpen}
                onClick={closeSelect3}
                clear={clear}
                name='Shops lists'
                icon={true}
                onSelect={handleShopSelect}
                placeholder='Select Shop'
              />
            </div>
          </div>
          <div className='stock-table'>
            {selectedItems.length > 0 && (
              <div className='bulk-actions'>
                <button
                  className='prev'
                  onClick={handleBulkDelete}
                >
                  <MdDelete /> Delete
                </button>
                {/* <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleBulkDelete}
                >
                  Delete
                </Button> */}
              </div>
            )}
            {itemStock && itemStock.length > 0 ? (
              <>
                {tableSpinner ? (
                  <Spinner />
                ) : (
                  <>
                    <table class='blueTable'>
                      <thead>
                        <tr>
                          <th>
                            <Checkbox
                              indeterminate={selectedItems.length > 0 && selectedItems.length < itemStock.length}
                              checked={itemStock.length > 0 && selectedItems.length === itemStock.length}
                              onChange={handleSelectAllClick}
                              inputProps={{ 'aria-label': 'select all items' }}
                            /></th>
                          <th className='short'>#</th>
                          <th>Barcode</th>
                          <th>Brand</th>
                          <th>Model</th>
                          <th>PartName</th>
                          <th>Color</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemStock.map((item, index) => (
                          <tr key={item._id}>
                            <td>
                              <Checkbox
                                // checked={false}
                                checked={isItemSelected(item._id)}
                                onChange={(event) => handleSelectItem(event, item._id)}
                                inputProps={{ 'aria-labelledby': `checkbox-${item._id}` }}
                              /></td>
                            <td>{index + 1}</td>
                            <td>{item.barCode}</td>
                            <td>{item.vender}</td>
                            <td>{item.model}</td>
                            <td>{item.partName}</td>
                            <td>{item.color}</td>
                            <td>{item.price}</td>
                            <td>{item.itemsInStock}</td>
                            <td>
                              <div className='navi'>
                                <button
                                  className='next'
                                  onClick={() => {
                                    openModals(item);
                                  }}
                                >
                                  <MdEdit /> Edit
                                </button>
                                <button
                                  className='prev'
                                  onClick={() => {
                                    openModalDelete(item._id);
                                  }}
                                >
                                  <MdDelete /> Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* <Pagination
                      currentPage={currentPage}
                      totalPages={Math.ceil(totalItems / itemsPerPage)}
                      onPageChange={handlePageChange}
                    /> */}
                  </>
                )}
              </>
            ) : (
              <>
                <table class='blueTable'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Barcode</th>
                      <th>Vendor</th>
                      <th>Model</th>
                      <th>PartName</th>
                      <th>Color</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                </table>
                <div className='no-data'>
                  <p>No data available</p>
                </div>
              </>
            )}
          </div>
          {isModalOpen && (
            <StockModal
              isOpen={isModalOpen}
              onClose={closeModal}
              addStockItem={(data) => addStockItem(data)}
            />
          )}
          <StockModalEdit
            isOpen={isModalOpens}
            onClose={closeModals}
            stockDetails={selectedStock}
            // showShopOption={false}
            phone={true}
            onUpdate={(data) => {
              updateStock(data);
            }}
          />
          <DeleteModal
            isDelete={deleteModal}
            ondeleteClose={closeModalDelete}
            selectedRecordId={selectedStockId}
            deleteRecord={deleteStock}
            deleteMsg={'Are you sure you want to delete this stock item?'}
            title='Delete Stock Item'
          />
        </div >
      )
      }
    </>
  );
}

export default Accessories;

