import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../Components/Input";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/Login.css";
import logo from "../Images/login.png";
import { loginUser } from "../Redux-store/userActions";
import { Link as MuiLink } from '@mui/material'
function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  // const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.user);

  const handleLoginEvent = async (e) => {
    e.preventDefault();
    let userCredentials = {
      userName,
      password,
    };

    try {
      await dispatch(loginUser(userCredentials));
      setUserName("");
      setPassword("");
      navigate("/home");
    } catch (error) {
      // setErrorMessage("Login Failed!. Please check your credentials!");
    }
  };

  return (
    <div className="loginPage">
      <div className="container">
        <figure>
          <img src={logo} alt="logo" />
        </figure>
        <Input
          type="text"
          placeholder="Enter Username"
          name="userName"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Enter Password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="remember-container">
          <div className="remember">
            <input type="checkbox" />
            <p>Remember me</p>
          </div>
          <Link href="/forget" onClick={(e) => {
            e.preventDefault()
            navigate("/forget")
          }}>Forget Password ?</Link>
          {/* <MuiLink className="" style={{
            cursor: "pointer",
            '&:hover': {
              color: "blue"
            }
          }}

            onClick={() => navigate("/forget")}
          >Forgot Password?<MuiLink /> */}
        </div>
        {error && <p className="error-message">{error}</p>}

        <Link to="/">
          <button onClick={handleLoginEvent}>
            {loading ? "...Loading" : "Login"}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Login;
