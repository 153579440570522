import React from "react";
import "../Styles/Modal.css"; // Import your CSS for modal styling
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import { ReactComponent as AddStaff } from "../Icons/staff.svg";
import Input from "./Input";
import { CheckBox } from "../Pages/InputBox";
const DeleteModal = ({
  isDelete,
  ondeleteClose,
  selectedRecordId,
  deleteRecord,
  deleteMsg,
  title,
}) => {

  if (!isDelete) return null;
  console.log("title", title);
  let title1 = title || "Add New Staff";

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="Upper-modal-row">
          <h2>{title1}</h2>
          <CloseIcon onClick={ondeleteClose} />
        </div>
        <div className="delete">
          <p>{deleteMsg}</p>
          <div>
            <button
              className="continue-modal-button"
              onClick={() => deleteRecord(selectedRecordId)}
            >
              Delete
            </button>
            <button
              className="continue-modal-button cancel"
              onClick={ondeleteClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
