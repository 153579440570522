import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../Styles/SelectOption/selec.css";
import { AiOutlineCaretDown } from "react-icons/ai";
function CustomSelect({ options, onSelect, name, icon, style, isOpen, setIsOpen, onClick, clear }) {

  const [selectedValue, setSelectedValue] = useState("");

  const handleOptionClick = (option) => {
    setSelectedValue(option.label);
    setIsOpen(false);
    onSelect(option.value);
  };
  useEffect(() => {
    console.log(clear)
    if (clear) {
      setSelectedValue("")
      console.log("cleared")
    }
  }, [clear])


  return (
    <div className="custom-select">
      <button className="opt-button" onClick={() => { setIsOpen(!isOpen); onClick() }} style={style}>
        {icon} <p>{selectedValue || name} </p>
        <AiOutlineCaretDown />
      </button>
      {isOpen && (
        <ul className="options">
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionClick(option)}>
              {option.label}   {option.label == selectedValue && <div className="bordercircle">

              </div>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CustomSelect;
