import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillCloseCircle, AiOutlineCaretDown } from 'react-icons/ai';
import BoxItem from '../Components/BoxItem/BoxItem';
import LineChart from '../Components/Chart/Chart';
import CombineSalesPieChart from '../Components/Chart/CombineSalesPieChart';
import DatePicker from '../Components/DatePicker/Date';
import GraphChart from '../Components/GraphChart';
import CustomSelect from '../Components/SelectOption/SelectOption';
import Spinner from '../Components/Spinner';
import { ReactComponent as DateIcon } from '../Icons/date.svg';
import '../Styles/Home/Home.css';
import { Button } from './Buttons';
import dayjs from 'dayjs';

function fillMissingMonths(salesData) {
  const filledData = [];
  const salesMap = new Map();
  salesData.forEach((entry) => {
    salesMap.set(entry.month, entry.totalSale);
  });
  for (let month = 1; month <= 12; month++) {
    if (salesMap.has(month))
      filledData.push({ month: month, totalSale: salesMap.get(month) });
    else filledData.push({ month: month, totalSale: 0 });
  }
  return filledData;
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getFormatDate = (d) => {
  const date = new Date(d);
  const yyyy = date.getFullYear();
  const mm = date.getMonth();
  const dd = date.getDate();
  return `${dd} ${months[mm]}, ${yyyy}`;
};


function Home() {
  const currentYear = new Date().getFullYear();
  const b1 = 'linear-gradient(221deg, #FF7C5C 23.46%, #FF5D36 101.25%)';
  const b2 = 'linear-gradient(180deg, #0484AC 0%, #006B8C 100%)';
  const b3 = 'linear-gradient(180deg, #CD5050 0%, #A62121 100%)';
  const b4 = 'linear-gradient(180deg, #0484AC 0%, #00AADF 100%)';

  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [showFromDate, setShowFromDate] = useState(false);
  const [showToDate, setShowToDate] = useState(false);

  const [fromDate, setFromDate] = useState(dayjs(new Date()));
  const [toDate, setToDate] = useState(dayjs(new Date()));
  const [staffStats, setStaffStats] = useState(0);
  const [stockStats, setStockStats] = useState({
    totalInStock: 0,
    totalOutOfStock: 0,
  });
  const [salesStats, setSalesStats] = useState(null);
  const [graphData, setGraphData] = useState();

  const [shopsList, setShopsList] = useState([]);
  const [openShopsListDropdown, setOpenShopsListDropdown] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);

  const fetchShopsOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`
      );
      const shops = response.data.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setShopsList(shops);
    } catch (error) {
      console.error('Error while fetching shop options: ', error);
    }
  };

  useEffect(() => {
    fetchShopsOptions();
  }, []);


  useEffect(() => {
    fetchGraphData();
    fetchSalesData()
  }, [toDate, fromDate]);

  useEffect(() => {
    fetchGraphData();
    fetchSalesData();
  }, [selectedShop]);

  const fetchSalesData = async () => {
    try {
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/cart/stat`;

      const startDate = fromDate;
      const endDate = toDate;
      if (startDate && endDate) {
        apiUrl += `?dateFrom=${startDate.format(
          'MM-DD-YYYY'
        )}&dateTo=${endDate.format('MM-DD-YYYY')}`;
      }
      const response = await axios.get(apiUrl, {
        params: { shop: selectedShop },
      });
      setSalesStats(response.data.data);
    } catch (error) {
      console.error('Error while fetching sales data:', error);
    }
  };

  const fetchGraphData = async () => {
    try {
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard`;


      const startDate = fromDate;
      const endDate = toDate;
      if (startDate && endDate) {
        apiUrl += `?dateFrom=${startDate.format(
          'MM-DD-YYYY'
        )}&dateTo=${endDate.format('MM-DD-YYYY')}`;
      }
      const response = await axios.get(
        apiUrl,
        {
          params: { shop: selectedShop },
        }
      );
      const graphAPI = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/graph`,
        {
          params: { shop: selectedShop },
        }
      );
      const graphDataResponse = response.data.data.graph;
      setGraphData(fillMissingMonths(graphAPI?.data?.data));
      setStaffStats(response.data.data.staffCount);
      setStockStats({
        sales: response.data.data.sales,
        soldItems: response.data.data.soldItems,
        totalInStock: response.data.data.itemsInStock,
        totalOutOfStock: response.data.data.soldItems,
      });
    } catch (error) {
      console.error('Error fetching graph data:', error);
    }
  };

  console.log('graphData', graphData);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const handleFromDateSelect = (date) => {
    setFromDate({ $y: date.$y });
    setShow(false);
  };

  const handleToDateSelect = (date) => {
    setToDate({ $y: date.$y });
    setShows(false);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='home-box-container'>
          <div className='row' style={{ margin: '1% 0%' }}>
            <CustomSelect
              options={[{ label: 'None', value: undefined }, ...shopsList]}
              isOpen={openShopsListDropdown}
              setIsOpen={setOpenShopsListDropdown}
              onClick={() => null}
              clear={true}
              name='Select a shop'
              icon={true}
              onSelect={(v) => setSelectedShop(v)}
              placeholder='Select Shop'
            />
          </div>
          <div
            className='sales-row'
            style={{
              justifyContent: 'flex-end',
              gap: '10px',
              marginBottom: '20px',
              display: 'flex',
            }}
          >
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <p style={{ marginBlock: 0 }}>From</p>
                <Button
                  text={getFormatDate(fromDate)}
                  icon={<DateIcon />}
                  bgcolor='#F3F3F3'
                  color='#000'
                  onClick={() => {
                    setShowFromDate((st) => !st);
                    setShowToDate(false);
                  }}
                />
              </div>
              {showFromDate && (
                <DatePicker
                  date={fromDate}
                  setToDate={setFromDate}
                  onClose={() => {
                    setShowFromDate((st) => !st);
                  }}
                />
              )}
            </div>
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <p style={{ marginBlock: 0 }}>To</p>
                <Button
                  text={getFormatDate(toDate)}
                  icon={<DateIcon />}
                  bgcolor='#F3F3F3'
                  color='#000'
                  onClick={() => {
                    setShowToDate((st) => !st);
                    setShowFromDate(false);
                  }}
                />
              </div>
              {showToDate && (
                <DatePicker
                  date={toDate}
                  setToDate={setToDate}
                  onClose={() => {
                    setShowToDate((st) => !st);
                  }}
                />
              )}
            </div>
          </div>
          <div className='row'>
            <BoxItem
              text='Total Sales'
              number={stockStats.sales}
              icon={true}
              background={b1}
            />
            <BoxItem
              text='Sold Items'
              number={stockStats.soldItems}
              icon={true}
              background={b2}
            />
            {/* <BoxItem
              text='Staff Members'
              number={staffStats}
              icon={false}
              background={b3}
            /> */}
            <BoxItem
              text='Items In Stock'
              number={stockStats.totalInStock}
              icon={false}
              background={b4}
            />
          </div>
          <div className='row' style={{ margin: '20px 0px 0px 0px' }}>
            <div
              className='bor-box'
              style={{ width: '100%', height: 'fit-content', marginTop: '0px' }}
            >
              <div className='rowp'>
                <h4>Sales Statistics</h4>
              </div>
              {graphData && <GraphChart data={graphData} />}
            </div>
            <CombineSalesPieChart salesStats={salesStats} />
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
