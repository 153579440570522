import React, { useState, useEffect } from "react";
import "../Styles/Modal.css"; // Import your CSS for modal styling
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import axios from "axios";

function EditShop({ isOpens, onCloses, shopDetails, onUpdate }) {
  // Initialize formData with default values
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });

  useEffect(() => {
    // Update formData with staffDetails when it's available
    if (shopDetails) {
      setFormData({
        name: shopDetails.name,
        email: shopDetails.email,
        mobile: shopDetails.mobile,
        password: shopDetails.password,
      });
    }
  }, [shopDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    console.log("formData",formData);
    onUpdate(formData);
  };

  return isOpens ? (
    <div className="modal-overlay">
      <div className="modal">
        <div className="Upper-modal-row">
          <h2>Edit Shop</h2>
          <CloseIcon onClick={onCloses} />
        </div>
        <div className="row">
          <div className="input-boxer">
            <input
              type="text"
              name="name"
              placeholder="Enter Name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-boxer">
            <input
              type="text"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="input-boxer">
            <input
              name="mobile"
              placeholder="Enter mobile"
              value={formData.mobile}
              onChange={handleInputChange}
            />
          </div>{" "}
          <div className="input-boxer">
            <input
              type="text"
              name="password"
              placeholder="Enter password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <button className="continue-modal-button" onClick={handleUpdate}>
          Update Details
        </button>
      </div>
    </div>
  ) : null;
}

export default EditShop;
