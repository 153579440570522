import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import BoxItem from '../Components/BoxItem/BoxItem';
import CreateNewModalShop from '../Components/CreateNewModalShop';
import DeleteShop from '../Components/DeleteShop';
import EditShop from '../Components/EditShop';
import SearchInput from '../Components/Search';
import Spinner from '../Components/Spinner';
import { ReactComponent as AdditonIcon } from '../Icons/addition.svg';
import '../Styles/StockReport/Users.css';

function Users() {
  const [shops, setShops] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [deleteModal, setModalDelete] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [editableStaffData, setEditableStaffData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchShops();
  }, [refresh, searchTerm]);

  const fetchShops = async () => {
    try {
      const url = searchTerm ? `shop/search?name=${searchTerm}?` : `shop`;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${url}`
      );
      setShops(response.data.data);
    } catch (error) {
      console.error('Error while fetching staff members: ', error);
    }
  };
  const updateShop = async (shopId, data) => {
    const { name, email, mobile, password } = data;
    setRefresh(true);
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/shop`, {
        name,
        email,
        mobile,
        password,
        shopId,
      });
      setRefresh(false); // This can be used to refresh the staff member list.
      closeModalEdit();
      toast.success('Shop details has been updated successfully');
    } catch (error) {
      console.error(`Error while updating Shop with ID ${shopId}:`, error);
      toast.error(`Error while updating Shop with ID ${shopId}:`, error);
    }
  };

  const addShop = async (data) => {
    const { name, mobile, password, email } = data;
    const staffPayload = {
      name,
      email,
      password,
      mobile,
    };
    setRefresh(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop`,
        staffPayload
      );
      setRefresh(false); // This can be used to refresh the staff member list.
      fetchShops();
      closeModal();
      toast.success('Shop has been registered successfully');
    } catch (error) {
      toast.error(
        error?.response?.data?.message || 'Error while adding the shop'
      );
    }
  };
  const deleteShop = async (selectedShopId) => {
    setRefresh(true);

    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/shop/${selectedShopId}`
      );
      setRefresh(false);
      setShops((prevShops) =>
        prevShops.filter((shop) => shop._id !== selectedShopId)
      );
      setModalDelete(false);
      toast.success('Shop is deleted successfully');
    } catch (error) {
      console.error('Error while deleting Shop : ', error);
      toast.error('Error while deleting Shop : ', error);
    }
  };
  function formatedDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModalEdit = (shopDetails) => {
    setEditableStaffData(shopDetails);
    setModalEdit(true);
  };

  const closeModalEdit = () => {
    setModalEdit(false);
  };
  const openModalDelete = (staffId) => {
    setModalDelete(true);
    setSelectedStaffId(staffId);
  };

  const closeModalDelete = () => {
    setModalDelete(false);
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const [tableSpinner, setTableSpinner] = useState(true);
  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setTableSpinner(false);
    }, 4000);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='user-container'>
          <div className='row-container'>
            <BoxItem
              text='Total-Shops'
              number={shops.length}
              icon={false}
              background='linear-gradient(180deg, #AD2626 0%, #EE5353 100%)'
            />
            <div
              className='button-box'
              onClick={() => {
                openModal();
              }}
            >
              <div className='icon'>
                <AdditonIcon />
              </div>
              <h3> Add New Shop</h3>
            </div>
          </div>
          <div className='hsls'>
            <div className='search-row'>
              <SearchInput
                placeholder='Shop Name, Email or Mobile'
                searchTerm={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className='stock-table'>
            {shops && shops.length > 0 ? (
              <>
                {tableSpinner ? (
                  <Spinner isLoading={tableSpinner} />
                ) : (
                  <>
                    <table class='blueTable'>
                      <thead>
                        <tr>
                          <th className='short'>#</th>
                          <th>Name</th>
                          <th>Password</th>
                          <th>Email</th>
                          <th>Created On</th>
                          <th>Mobile</th>
                          <th className='long'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shops?.map((shop, index) => (
                          <tr key={index}>
                            <td>{shop?.shopNo || '-'}</td>
                            <td>{shop.name}</td>
                            <td>{shop?.password ?? '-'}</td>
                            <td>{shop.email}</td>

                            <td>{formatedDate(shop.createdAt)}</td>
                            <td>{shop.mobile}</td>

                            <td>
                              <div className='navi'>
                                <button
                                  className='next'
                                  onClick={() => {
                                    openModalEdit(shop);
                                  }}
                                >
                                  <MdEdit /> Edit
                                </button>
                                <button
                                  className='prev'
                                  onClick={() => {
                                    openModalDelete(shop._id);
                                  }}
                                >
                                  <MdDelete />
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            ) : (
              <>
                <table class='blueTable'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Created On</th>
                      <th>Mobile</th>
                      <th className='long'>Created On</th>
                    </tr>
                  </thead>
                </table>
                <div className='no-data'>
                  <p>No data available</p>
                </div>
              </>
            )}
          </div>
          <CreateNewModalShop
            isOpen={isModalOpen}
            onClose={closeModal}
            addShop={addShop}
          />
          <EditShop
            isOpens={modalEdit}
            onCloses={closeModalEdit}
            shopDetails={editableStaffData}
            onUpdate={(data) => updateShop(editableStaffData._id, data)}
          />
          <DeleteShop
            isDelete={deleteModal}
            ondeleteClose={closeModalDelete}
            selectedRecordId={selectedStaffId}
            deleteRecord={deleteShop}
          />
        </div>
      )}
    </>
  );
}

export default Users;
