import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../Components/Input";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/Login.css";
import logo from "../Images/login.png";
import { loginUser } from "../Redux-store/userActions";
import axios from "axios";
import { toast } from "react-toastify";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  // const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.user);

  const handleLoginEvent = async (e) => {
    e.preventDefault();
    let userCredentials = {
      otp,
      password,
      passwordConfirm
    };

    try {
      const res = await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/authentication/resetPassword`, userCredentials);
      setEmail("");
      setPassword("");
      setPasswordConfirm("");
      toast.success("Password reset successfully");
      navigate("/home");
    } catch (error) {
      // setErrorMessage("Login Failed!. Please check your credentials!");
    }
  };

  return (
    <div className="loginPage">
      <div className="container">
        <figure>
          <img src={logo} alt="logo" />
        </figure>
        <Input
          type="number"
          placeholder="Enter OTP"
          name="otp"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />


        <Input
          type="password"
          placeholder="Enter New Password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          name="password"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />

        <div className="remember-container">
          <div className="remember">
            {/* <input type="checkbox" /> */}
            <p></p>
          </div>
          <Link href="/login" onClick={(e) => {
            e.preventDefault()
            navigate("/login")
          }}>Login</Link>
        </div>
        {error && <p className="error-message">{error}</p>}

        <Link to="/">
          <button onClick={handleLoginEvent}>
            {loading ? "...Loading" : "Reset Password"}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default ResetPassword;
