import React, { useState, useEffect } from "react";
import "../Styles/Modal.css";
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import { ReactComponent as AddStaff } from "../Icons/staff.svg";
import Input from "./Input";
import { CheckBox } from "../Pages/InputBox";
import Pin from "./Pin";
import axios from "axios";
import { toast } from "react-toastify";
import VerifyOTPModel from "./VerifyOTPModel";
import { useSelector } from "react-redux";

function RequestOTPModel({ isOpen, onClose, closeEmailModal, isChangeEmail }) {
  const [showPin, setShowPin] = useState(false);
  const [formData, setFormData] = useState({ email: "" });
  const [isInputValid, setIsInputValid] = useState(false);

  const { user } = useSelector(state => state.user)

  const requestOTP = async (email) => {
    let requstData;
    if (isChangeEmail) {
      requstData = { newEmail: email, email: user?.email }
    } else {
      requstData = { email: email };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/authentication/verifyOtp`,
        requstData
      );

      console.log("OTP sent", response.data);
      if (isChangeEmail) {
        localStorage.setItem("newEmail", JSON.stringify(requstData))
      }
      toast.success("OTP sent");
      // Check response status before showing PIN
      if (response.status === 200) {
        setShowPin(true);
      }


      return response.data;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/authentication/requestOtp`,
        requstData
      );

      console.log("OTP sent", response.data);
      if (isChangeEmail) {
        localStorage.setItem("newEmail", JSON.stringify(requstData))
      }
      toast.success("OTP sent");
      // Check response status before showing PIN
      if (response.status === 200) {
        setShowPin(true);
      }


      return response.data;
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Error while sending OTP");
      throw error;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsInputValid(!!value.trim());
  };

  const handleSubmit = async () => {
    if (!isInputValid) {
      toast.error("Please enter a valid email address");
      return;
    }
    try {
      setFormData({ email: "" })
      await requestOTP(formData.email);
    } catch (error) {
      // Handle error if needed
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal">
        <div className="Upper-modal-row">
          <h2>Request OTP</h2>
          <CloseIcon
            onClick={() => {
              onClose();
              setFormData({ email: "" });
            }}
          />
        </div>
        {showPin ? (
          <div className="piner">
            {isChangeEmail ? (<p>
              A email reset OTP has been sent to your new entered email.
              Please enter it below:
            </p>) :
              (<p>
                A password reset OTP has been sent to your email.
                Please enter it below:
              </p>
              )}
            <Pin closeModal={setShowPin} closeEmailModal={closeEmailModal} isChangeEmail={isChangeEmail} />
          </div>
        ) : (
          <div className="row" style={{ flexDirection: "column", gap: "20px" }}>
            <div className="input-boxer">
              <input
                type="email"
                name="email"
                placeholder={isChangeEmail ? "Enter new email" : "Enter email"}
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        )}
        <button className="continue-modal-button" onClick={handleSubmit} >
          <AddStaff />
          Request OTP
        </button>
      </div>
    </div>
  ) : null;
}

export default RequestOTPModel;
